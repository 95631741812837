jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      cellAlign: 'left',
      contain: false
    }

    slider.flickity(config)
  })


  $('.logo-slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      autoPlay: 2500,
      contain: false
    }

    slider.flickity(config)
  })


  $('.banner-slider').each(function() {
    var slider = $(this)
    var config = {
      prevNextButtons: false,
      wrapAround: true,
      imagesLoaded: true,
      autoPlay: 5000,
      pageDots: slider.hasClass('has-dots'),
      contain: false
    }

    slider.flickity(config)
  })

  $('.carousel-main').flickity();
  $('.carousel-nav').flickity({
    asNavFor: '.carousel-main',
    wrapAround: true,
    imagesLoaded: true,
    prevNextButtons: false,
    pageDots: false,
    draggable: false,
    cellAlign: 'left',
    contain: false,
  });
})