jQuery(function($) {
  $('.accordion-item--title').click(function() {
    var accordionContent = $(this).next('.accordion-item--content')
    var accordionContentHeight = accordionContent[0].scrollHeight

    $(this).toggleClass('active')

    if($(this).hasClass('active')) {
      accordionContent.css('max-height', `${accordionContentHeight}px`)
    } else {
      accordionContent.css('max-height', `0px`)
    }
  })
})

// Shop Filter
jQuery(function($) {
  $('.filter').click(function() {
    var accordionContent = $(this).children('.dropdown-categories')
    var accordionContentHeight = accordionContent[0].scrollHeight

    $(this).toggleClass('active')

    if($(this).hasClass('active')) {
      accordionContent.css('max-height', `${accordionContentHeight}px`)
    } else {
      accordionContent.css('max-height', `0px`)
    }
  })
})