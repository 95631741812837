let headerBottom = jQuery('.header-bottom').offset().top;
let headerBottomPadding = jQuery('.header-bottom').outerHeight();


if(jQuery('.woocommerce-store-notice__dismiss-link').css('display') == 'none') {
  headerBottom = jQuery('.header-bottom').offset().top;
}

jQuery('.woocommerce-store-notice__dismiss-link').on('click', function() {
  headerBottom = jQuery('.header-bottom').offset().top;
});

jQuery(window).on('scroll', function() {
  if(headerBottom < window.pageYOffset) {
    jQuery('.header-bottom').css('position', `fixed`);
    jQuery('main').css('paddingTop', `${headerBottomPadding}px`);
    jQuery('header').addClass('scroll-active');

  } else {
    jQuery('.header-bottom').css('position', `relative`);
    jQuery('main').css('paddingTop', `0px`);
    jQuery('header').removeClass('scroll-active');
  }
});


jQuery(window).on('load', function() {
  if(jQuery('.woocommerce-store-notice__dismiss-link').css('display') == 'none') {
    headerBottom = jQuery('.header-bottom').offset().top;
  }
  
  jQuery('.woocommerce-store-notice__dismiss-link').on('click', function() {
    headerBottom = jQuery('.header-bottom').offset().top;
  });

  if(headerBottom < window.pageYOffset) {
    jQuery('.header-bottom').css('position', `fixed`);
    jQuery('main').css('paddingTop', `${headerBottomPadding}px`);
    jQuery('header').addClass('scroll-active');

  } else {
    jQuery('.header-bottom').css('position', `relative`);
    jQuery('main').css('paddingTop', `0px`);
    jQuery('header').removeClass('scroll-active');
  }
});


jQuery(window).on('resize', function() {
  headerBottom = jQuery('.header-bottom').offset().top;
  headerBottomPadding = jQuery('.header-bottom').outerHeight();

  if(jQuery(window).width() < 1024) {
    if(jQuery('.woocommerce-store-notice__dismiss-link').css('display') == 'none') {
      headerBottom = jQuery('.header-bottom').offset().top;
    }
    
    jQuery('.woocommerce-store-notice__dismiss-link').on('click', function() {
      headerBottom = jQuery('.header-bottom').offset().top;
    });
  
    if(headerBottom < window.pageYOffset) {
      jQuery('.header-bottom').css('position', `fixed`);
      jQuery('main').css('paddingTop', `${headerBottomPadding}px`);
      jQuery('header').addClass('scroll-active');
  
    } else {
      jQuery('.header-bottom').css('position', `relative`);
      jQuery('main').css('paddingTop', `0px`);
      jQuery('header').removeClass('scroll-active');
    }

  } else {
    if(jQuery('.woocommerce-store-notice__dismiss-link').css('display') == 'none') {
      headerBottom = jQuery('.header-bottom').offset().top;
    }
    
    jQuery('.woocommerce-store-notice__dismiss-link').on('click', function() {
      headerBottom = jQuery('.header-bottom').offset().top;
    });
  
    if(headerBottom < window.pageYOffset) {
      jQuery('.header-bottom').css('position', `fixed`);
      jQuery('main').css('paddingTop', `${headerBottomPadding}px`);
      jQuery('header').addClass('scroll-active');
  
    } else {
      jQuery('.header-bottom').css('position', `relative`);
      jQuery('main').css('paddingTop', `0px`);
      jQuery('header').removeClass('scroll-active');
    }
  }
});